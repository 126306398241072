import React, { ReactNode } from 'react'
import * as stylesModule from './_styles.module.scss'
import { clsx } from 'clsx'

type Props = {
    children: ReactNode
    className?: string
}

/**
 * A section component with rounded bottom corners achieved through an additional background element.
 *
 * @component
 * @example
 * ```tsx
 * <Section>
 *   <h1>Section Content</h1>
 * </Section>
 * ```
 *
 * @remarks
 * The component includes a .nextSectionBackground element that can be styled globally using
 * the :global(.nextSectionBackground) selector in SCSS modules. This element is used to
 * create a visual transition between sections with rounded corners.
 *
 * @example
 * ```scss
 * // In your .scss module file
 * .yourSection {
 *   :global(.nextSectionBackground) {
 *     background-color: #f0f0f0;
 *   }
 * }
 * ```
 *
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - Content to be rendered inside the section
 * @param {string} [props.className] - Additional CSS class names to apply to the section
 */
export const Section = ({ children, className }: Props) => {
    const styles: any = stylesModule

    return (
        <section className={clsx(styles.container, className)}>
            {children}
            <div className={'nextSectionBackground'} />
        </section>
    )
}
