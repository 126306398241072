import React from 'react'
import { clsx } from 'clsx'
import * as stylesModule from './_styles.module.scss'

type Props = {
    children: React.ReactNode
    className?: string
}

export const CardTitle = ({ children, className }: Props) => {
    const styles: any = stylesModule

    return <p className={clsx(styles.text, className)}>{children}</p>
}
