import React from 'react'
import { clsx } from 'clsx'
import * as stylesModule from './_styles.module.scss'

type Props = {
    src: string
    alt: string
    className?: string
}

export const CardImage = ({ src, alt, className }: Props) => {
    const styles: any = stylesModule

    return <img className={clsx(styles.image, className)} src={src} alt={alt} />
}
