import React, { ReactNode } from 'react'
import { clsx } from 'clsx'
import * as stylesModule from './_styles.module.scss'

type Props = {
    children: ReactNode
    className?: string
}

export const SectionTitle = ({ children, className }: Props) => {
    const styles: any = stylesModule

    return <h2 className={clsx(styles.text, className)}>{children}</h2>
}
