import React, { ReactNode } from 'react'
import { clsx } from 'clsx'
import * as stylesModule from './_styles.module.scss'

type Props = {
    icon: ReactNode
    className?: string
}

export const CardIcon = ({ icon, className }: Props) => {
    const styles: any = stylesModule

    return <section className={clsx(styles.container, className)}>{icon}</section>
}
