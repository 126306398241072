import React, { ReactNode, MouseEvent } from 'react'
import * as stylesModule from './_styles.module.scss'
import { clsx } from 'clsx'

type Props = {
    children: ReactNode
    className?: string
}

export const Card = ({ children, className }: Props) => {
    const styles: any = stylesModule

    const handleClick = (event: MouseEvent<HTMLLIElement>) => {
        const link = (event.currentTarget as HTMLLIElement).querySelector('a')

        if (link && event.target !== link) {
            link.click()
        }
    }

    return (
        <li className={clsx(styles.container, className)} onClick={handleClick}>
            {children}
        </li>
    )
}
