import React from 'react'
import * as myStyles from './styles.module.scss'
import HeroModule from '../../lib/components/modules/hero'
import {
    heroProps,
    preFooterTitle,
    cardsDataPrefooter,
    graphicsAndLogos,
    featuredStoriesCardsData,
} from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import GraphicLogos from '../../lib/components/modules/graphic-logos'
import useResponsive from '../../lib/hooks/useResponsive'
import { BREAKPOINTS } from '../../lib/constants/breakpoints'
import backgroundImageMobile from '../../../resources/images/hanzo/testimonials/background_mobile.webp'
import backgroundImage from '../../../resources/images/hanzo/testimonials/background.webp'
import { Section } from '../../../components/section'
import { SectionTitle } from '../../../components/section-title'
import { CardGroup } from '../../../components/card-group'
import { Card } from '../../../components/card'
import { CardImage } from '../../../components/card-image'
import { CardTag } from '../../../components/card-tag'
import { CardTitle } from '../../../components/card-title'
import { CardIcon } from '../../../components/card-icon'
import { Link } from 'gatsby'

const CustomersPage = () => {
    const styles: any = myStyles
    const isMobile = useResponsive({ threshold: BREAKPOINTS.md })

    return (
        <section className={styles.content}>
            <section className={styles.heroSection}>
                <HeroModule
                    {...heroProps}
                    background={{
                        image: {
                            src: isMobile ? backgroundImageMobile : backgroundImage,
                            alt: 'pattern',
                        },
                    }}
                />
            </section>

            <Section className={styles.storiesSection}>
                <SectionTitle>Featured Success stories</SectionTitle>
                <CardGroup>
                    {featuredStoriesCardsData.map((card, index) => (
                        <Card key={index}>
                            <CardImage src={card.imageSrc} alt={card.imageAlt} />
                            <CardTag>Case Study</CardTag>
                            <CardTitle>
                                <Link to={card.href}>{card.title}</Link>
                            </CardTitle>
                            <CardIcon icon={card.logo} />
                        </Card>
                    ))}
                </CardGroup>
            </Section>

            <div className={styles.bg_grey_black}>
                <GraphicLogos {...graphicsAndLogos} />
            </div>
            <div className={styles.bg_grey_20}>
                <PreFooter title={preFooterTitle} cardsData={cardsDataPrefooter} />
            </div>
        </section>
    )
}

export default CustomersPage
